@mixin nav-item-separator($color, $length) {
  position: relative;

  + .nav-item::after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: $length;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-left: 1px solid $color;
  }
}

@mixin headings() {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    @content;
  }
}

@mixin pseudo-element($element) {
  position: relative;

  &::#{$element} {
    content: '';
    position: absolute;
    display: block;
    @content;
  }
}