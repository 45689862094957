@include media-breakpoint-down(sm) {
  .navbar {
    padding: 0;
  }
}

@include media-breakpoint-down(md) {
  .navbar {
    .navbar-nav .nav-link {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.navbar-nav {
  width: 100%;

  .nav-link {
    @include font-size($navbar-link-font-size);
    background-color: $navbar-link-bg;
    transition: background-color .2s ease, border .2s ease, color .2s ease, opacity .2s ease-in-out;
    white-space: nowrap;

    @include hover-focus {
      background-color: $navbar-link-hover-bg;
    }

    &.disabled {
      background-color: $navbar-link-disabled-bg;
    }
  }
  div.nav-link {
    cursor: pointer;
  }

  .nav-spacer {
    flex: 1;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .show > .nav-link,
  .active > .nav-link,
  .nav-link.show,
  .nav-link.active {
    background-color: $navbar-link-active-bg;
  }

  .nav-item.pur-mgmt {
    position: relative;

    &:after,
    & li:after {
      display: none !important;
    }

    @include media-breakpoint-down(sm) {
      ul.dropdown-menu .nav-link {
        padding-left: 3em;
      }
    }
    @include media-breakpoint-up(sm) {
      ul.dropdown-menu {
        display: none;
        right: 0;
        z-index: 100;

        .nav-item {
          border-top: 1px solid $navbar-item-separation-border-color;
        }
      }

      &:hover ul.dropdown-menu {
        display: block;
      }
      &:hover > .nav-link {
        background-color: $navbar-link-hover-bg;
        color: $navbar-light-hover-color;
      }
    }
  }
}

.navbar-nav .nav-link {
  padding-top: $navbar-nav-link-padding-y;
  padding-bottom: $navbar-nav-link-padding-y;
}


@include media-breakpoint-down(sm) {
  // Horizontal lines between open menu items
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    .navbar-nav > .nav-item + .nav-item {
      border-top: 1px solid $navbar-item-separation-border-color;
    }
  }
}

.navbar-toggler {
  color: $navbar-toggler-color;
  border-color: $navbar-toggler-border-color;

  @include hover-focus-active {
    outline: 0;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.navbar-toggler-icon {
  background-image: $navbar-toggler-icon-bg;
}

.icon-hamburger {
  .line {
    display: block;
    width: 29px;
    height: 2px;
    border-radius: 1px;
    margin: 6px 0;
    transition: all 0.2s;
    background: $hamburger-color;

    &.line-1 {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }

    &.line-2 {
      opacity: 0;
      filter: alpha(opacity=0);
    }

    &.line-3 {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
    }
  }
}

.collapsed .icon-hamburger {
    .line-1 {
      transform: rotate(0);
    }

    .line-2 {
      opacity: 1;
    }

    .line-3 {
      transform: rotate(0);
    }
}
