.teaser {
  @extend .card;
  margin-bottom: 1rem;

  a, img {
    display: block;
  }

  a:not(.more) {
    @include plain-hover-focus {
      color: inherit;
      text-decoration: none;
    }
  }

  a.more {
    font-weight: $font-weight-bold;
    text-decoration: underline;
    @include font-size(0.75rem);
  }

  .teaser-tag-list {
    color: #9f9f9f;
    font-size: 11px;
    margin-top: 2px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .teaser-title {
    @include font-size(1.5rem);
  }
}

.teaser.teaser-lead {
  .teaser-title {
    @include font-size(1.125rem);
  }
  p {
    @include font-size(0.875rem);
  }
}

.teaser.teaser-sidebar .teaser-title {
  @include font-size(1rem);
  margin-top: 2px;
  margin-bottom: 0;
}

.teaser.teaser-compact {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  .teaser-media {
    width: calc(40% - 10px);
  }

  .teaser-body {
    width: calc(60% - 10px);
  }

  .teaser-title {
    @include font-size(1rem);
  }
}

.teaser.teaser-minimal {
  .teaser-title {
    @include font-size(0.875rem);
    margin-bottom: 0;
  }
}

.teaser-media {
  //margin-bottom: 2px;
}

.teaser-list {
  list-style-type: none;
  padding-left: 0;

  > li {
    border-top: 1px solid #e6e6e6;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .teaser {
    margin-bottom: 0;
  }
}

.layout-responsive .teaser-row {
  > .col {
    flex: 0 0 100%;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex: 0 0 50%;
      width: 50%;
    }
  }

  > .col-4 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      flex: 0 0 (100% / 3);
      width: (100% / 3);
      max-width: (100% / 3);
    }
  }
}

.teaser-row.related-articles {
  > .col {
    flex: 0 0 (100% / 3);
    width: (100% / 3);
    max-width: (100% / 3);
  }

  .layout-responsive & > .col {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;

      @include media-breakpoint-up(sm) {
        flex: 0 0 (100% / 2);
        width: (100% / 2);
        max-width: (100% / 2);
      }

      @include media-breakpoint-up(lg) {
        flex: 0 0 (100% / 3);
        width: (100% / 3);
        max-width: (100% / 3);
      }
    }
}