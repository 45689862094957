.fb-widget-page {
  overflow: hidden;
  position: relative;

  > a {
    display: block;
  }

  .widget-bg {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .widget-header {
    position: absolute;
    padding: 8px;
    top: 0;
    right: 0;
    left: 0;
    height: 70%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, 0) 100%);
    display: flex;
    flex-wrap: nowrap;

    .widget-page-icon {
      border: 2px solid white;
    }

    .widget-page-title {
      color: white;
      text-shadow: 0 2px 4px rgba(0, 0, 0, .9);
      margin-left: 4px;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.358;
      margin-bottom: -5px;
      white-space: nowrap;
    }
  }

  .fb-btn-like {
    position: absolute;
    left: 8px;
    bottom: 8px;
    border: 1px solid rgba(0, 0, 0, .12);
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    background-color: #f5f6f7;
    color: #4b4f56;
    white-space: nowrap;
    padding: 0 8px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    > img {
      display: block;
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
}