.embed-wrap {
  margin: auto;
  max-width: 500px;
  border: 1px solid #ccc;
  padding: 1em 4em;
  padding-left: clamp(1em, 10vw, 4em);
  padding-right: clamp(1em, 10vw, 4em);
  border-radius: 0.5em;
}

.embed-wrap.hidden {
  opacity: 0;
}

.embed-wrap header {
  color: #444;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5em;
}

.embed-wrap header div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
}

.embed-wrap header svg {
  height: 2.5em;
}

.embed-wrap p {
  margin: 16px 0;
}

.embed-wrap .action {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #444;
  font-weight: 600;
  cursor: pointer;
}

.embed-wrap .switch {
  background: #bbb;
  width: 70px;
  min-width: 70px;
  height: 30px;
  border-radius: 15px;
  position: relative;
  transition: 0.3s ease-in-out;
  margin-right: 0.5em;
}

.embed-wrap .switch .knob {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: 0.3s ease-in-out;
  background: white;
}

.embed-wrap .switch.active .knob,
.embed-wrap .active .switch .knob {
  left: 44px;
}

.embed-wrap .switch.active,
.embed-wrap .active .switch {
  background: #4caf50;
}

.embed-wrap .snd {
  font-size: 0.8em;
}