.sidebar {
  flex: 0 0 $main-sidebar-width;

  @include pseudo-element('after') {
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    border-left: $border;
  }

  .card {
    width: $main-sidebar-width;
  }

  .card + .card,
  .widget + .card {
    margin-top: 30px;
  }

  .card + .sticky-top {
    margin-top: 1.5rem;
  }

  .card-header {
    padding-top: 1.25rem;
    text-transform: uppercase;
  }

  .filled .card-header {
    color: #000;
  }

  .card-body {
    padding: 0 1.25rem 1.25rem 1.25rem;
    @include font-size(.9em);
  }

  .teaser-list {
    margin-bottom: 0;

    li {
      border-top: none;
      padding-top: 0;
      padding-bottom: 0;
    }

    li + li {
      padding-top: 15px;
      margin-top: 15px;
      border-top: $border;
    }
  }
}

@include media-breakpoint-down(lg) {
  .layout-responsive .sidebar {
    display: none;
  }
}
