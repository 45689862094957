.section-head {
  border-bottom: $border;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  a {
    @include plain-hover-focus {
      color: inherit;
      text-decoration: none;
    }
  }

  .more {
    display: block;
    @include font-size(.75rem);
    line-height: 12px;
    font-family: Arial, sans-serif;
    font-weight: 400;
    border: 1px solid #cccccc;
    height: 20px;
    padding: 3px;
  }

  .more {
    @include plain-hover-focus {
      color: #9f9f9f;
    }
  }
}

.section-caption {
  @include font-size(1rem);
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  margin: 0;
}