/* oswald-300 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('/static/fonts/google/oswald/oswald-v35-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/static/fonts/google/oswald/oswald-v35-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* oswald-regular - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/static/fonts/google/oswald/oswald-v35-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/static/fonts/google/oswald/oswald-v35-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
