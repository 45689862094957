$affiliate-background: #f5f5f5;
.amazon-widget {
    background: $affiliate-background;
    margin-bottom: 1.5rem;

    img {
        max-width: 33vw;
    }

    & .price {
        font-size: clamp(1.2rem, 4vw, 1.5rem);
    }

    & a.btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .25em;
        padding: .375rem;
        padding: .375rem clamp(.375rem, 1vw, .75rem);
    }
}
.affiliate-disclaimer {
    display: flex;
    align-items: center;
    background: $affiliate-background;
    gap: .5em;
    font-size: .9em;
    margin-bottom: .5rem;

    & .icon {
        display: flex;
        align-items: center;
        background-color: $primary;
        color: color-yiq($primary);
        padding: .5em;
        border-radius: .25em;
    }
}
.article-media + .article-story > .affiliate-disclaimer {
    margin-top: -0.5rem;
}
