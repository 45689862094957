article {
  .article-nav {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .pagination {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    border-radius: 0;
    background-color: $pagination-bg;
    margin-bottom: 0;
    list-style-type: none;
    padding-left: 0;

    .btn {
      border-radius: 0;
      color: #fff;
      background-color: $primary;
      @include font-size(1.125rem);

      @include hover-focus-active {
        background-color: darken($primary, 10%);
      }
    }
  }

  &.gallery .pagination {
    justify-content: space-between;
  }

  .pagination-position {
    color: $pagination-color;
  }
}

.article-icon-share {
  list-style-type: none;
  padding: 0;
  margin: 1.5rem 0;
  display: flex;
  justify-content: space-evenly;

  li {
    display: inline-block;
  }

  @include media-breakpoint-down(md) {
    article &.touch-optimized a{
      padding: 1rem 1.5rem;
    }
  }

  a {
    @include plain-hover-focus {
      text-decoration: none;
    }
    @extend .btn;
    border-radius: 0;
    padding: 0;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
    span:hover {
      opacity: 0.7;
    }
  }

  svg {
    height: 20px;
  }

  &:not(.mobile) {
    a span {
      width: 52px;
      height: 52px;
    }
    svg {
      height: 25px;
    }
  }


  @mixin white-inner-svg {
    svg {
      @include plain-hover-focus {
        color: #fff;
      }
    }
  }

  .icon-whatsapp {
    @include white-inner-svg;
    a span {
      background-color: $whatsapp-color;
    }
  }

  .icon-pinterest {
    @include white-inner-svg;
    a span {
      background-color: $pinterest-color;
    }
  }

  .icon-twitter {
    @include white-inner-svg;
    a span {
      background-color: #b8b8b8;
      filter: invert(1);
    }
  }

  .icon-facebook {
    @include white-inner-svg;
    a span {
      background-color: $facebook-color;
    }
  }

  .icon-youtube {
    @include white-inner-svg;
    a span {
      background-color: $youtube-color;
    }
  }
}

.article-head {
  font-size: 30px;
  margin-bottom: 25px;

  h1 {
    @include font-size(1em);
    margin-bottom: 0;
    line-height: 1.3;
    font-weight: $font-weight-bold;
  }

  time {
    @include font-size(1rem);
    margin: 0.5em auto;
    display: block;
  }

  address:has(~ time):after {
    content: ', ';
  }

  p {
    @include font-size(0.667em);
    margin-bottom: 0;
    font-weight: $font-weight-bold;
  }

  .byline {
    font-size: 1rem;
  }
}

.article-media {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.article-image > img {
  width: 100%;
}

.article-embed {
  &.provider-youtube:not(.embed-blocked) {
    position: relative;
    padding-bottom: 56.25%; /*16:9*/
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  > * {
    margin-bottom: 0 !important;
  }
}

.article-embed:not(.provider-youtube),
.article-embed:not(.provider-youtube) + footer {
  max-width: 100%;
  margin: 0 auto;
}

.article-embed.provider-twitter,
.article-embed.provider-twitter + footer {
  width: 500px;
}

.article-embed.provider-instagram {
  overflow-x: auto;
}
.article-embed.provider-instagram,
.article-embed.provider-instagram + footer {
  width: 658px;
}

.article-embed.provider-facebook,
.article-embed.provider-facebook + footer {
  width: 552px;
}
.article-embed.provider-facebook * {
  max-width: 100% !important;
}
.article-embed.provider-pinterest {
  text-align: center;
}

.article-media-source,
.article-media-privacy {
  margin-top: 2px;

  @include font-size(0.688rem);
  color: #9f9f9f;

  a {
    cursor: pointer;
    @include plain-hover-focus {
      color: #555;
    }
    @include hover-focus {
      text-decoration: underline;
    }
  }
}

.article-story {
  @include font-size(1rem);

  p {
    margin-bottom: 1.5rem;
    text-align: justify;
  }

  p.transition{
    font-style: italic;
  }
  a {
    @include hover-focus-active {
      text-decoration: underline;
    }
  }

  h3, h4 {
    @include font-size(1.25rem);
    color: $story-headings-color;
    font-family: $story-headings-font;
    font-weight: $story-headings-font-weight;
  }

  ul, ol {
    margin-bottom: 1.25rem;

    &:not(.list-unstyled) {
      margin-left: 1.25rem;
    }
  }

  ul:not(.list-unstyled) li {
    list-style: square;
    margin: 10px 0;
  }

  ol li {
    list-style: decimal;
    margin: 10px 0;
  }
}

.article-comments {
  background-color: #e9e9e9;
  margin: 30px 0 20px 0;
  max-width: 728px;
  overflow: hidden;
}

.related-articles {
  .teaser-title {
    @include font-size(1.125rem);
  }
}
