$font-family-sans-serif: 'Oswald', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: 'Lato', sans-serif;

$body-color: #666;
$body-bg: #333;

$headings-color: #000;
$headings-font-weight: bold;
$headings-font-family: $font-family-base;

$primary: #980002;

$link-color: $primary;
$link-hover-color: $primary;

$navbar-padding-y: 0;

$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-padding-y: 8px;

$nav-tabs-link-active-bg: $primary;

$navbar-light-color:                #333;
$navbar-light-hover-color:          #fff;
$navbar-light-active-color:         #fff;
$navbar-light-disabled-color:       rgba(#fff, .3);

$navbar-link-hover-bg: $primary;
$navbar-link-active-bg: $primary;

$hamburger-color: #333;

$story-headings-color: #000;
$story-headings-font: $font-family-base;

@import "../../fonts/lato";
@import "../../fonts/oswald";
@import "../../magazine";

.brand-logo img {
  height: 40px;

  @include media-breakpoint-up(md) {
    height: 60px;
  }
}

.navbar {
  border-top: 2px solid $black;
  padding-right: 0;
  padding-left: 0;

  @include media-breakpoint-down(sm) {
    .navbar-collapse.collapsing,
    .navbar-collapse.show {
      border-bottom: 2px solid $black;
    }

    .navbar-nav {
      padding: 0 10px;
    }
  }

  @include media-breakpoint-up(md) {
    border-bottom: 1px solid #ddd;

    .navbar-nav {
      .nav-item {
        @include nav-item-separator(#E9E9E9, $navbar-link-font-size);
        padding: 6px;

        &.pur-mgmt > ul {
          margin-top: 6px;
          background-color: #fff;
        }
      }
    }
  }

  .navbar-nav {
    .nav-link {
      color: #333;
      font-family: $font-family-sans-serif;
      text-transform: uppercase;
      @include font-size(1.125rem);

      @include hover-focus {
        background-color: $nav-tabs-link-active-bg;
      }
    }
  }
}

.sidebar .card.filled {
  background-color: #E9E9E9;
}

.footer-body {
  border-top: 1px solid #e9e9e9;
}

.article-head h1 {
  font-family: $font-family-base;
  color: $black;
}

.article-media-source a {
  @include plain-hover-focus {
    text-decoration: underline !important;
  }
}

.section-caption {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-normal;
  color: $black;
}

.sidebar .card-header {
  color: $black;
}

.article-story  {
  h3 {
    @include font-size($h3-font-size)
  }

  h4 {
    @include font-size($h4-font-size)
  }
}

.teaser-title {
  color: #606060;
}
