.archive-section-title {
  @include font-size(1.25rem);
}

.archive-list {
  list-style-type: none;
  @include font-size(1.125rem);

  a, a:hover {
    color: #888;
  }
}