footer {
  @include font-size(.75rem);
  color: #333;

  a {
    @include plain-hover-focus {
      color: #333;
    }
  }

  .list-inline {
    @include list-unstyled;
    text-align: center;

    .layout-fixed & {
      margin: 0;
    }

    li {
      display: inline-block;
      list-style: none;
      padding: 0 10px;
      margin: 5px 0;
    }

    @include media-breakpoint-up(md) {
      margin: 0;

      li + li {
        border-left: $border;
      }
    }
  }
}

.copyright {
  padding: 5px 10px;
  white-space: nowrap;
}

.footer-body {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .layout-fixed & {
    flex-direction: row !important;
  }
}