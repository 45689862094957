.container.inner-page .container.ads-container {
  width: 100%;
}

.da-container {
  padding: 0;
  text-align: center;

  .min-width-da {
    text-align: center;
  }

  + .da-container {
    margin-top: 1.5rem;
  }
}

.sidebar #nativendo-sidebar .thumbnails-dfp-320x250,
.sidebar #nativendo-sidebar .thumbnails-320x250 {
  width: auto !important;
}

div[data-ads-reserve-space] {
  // Reserve space
  &[data-ads-slot="banner"] {
    min-height: 250px;
    margin: 0 6px;
  }
  &[data-ads-slot="banner2"] {
    min-height: 90px;
  }
  &[data-ads-slot="topmobile"] {
    min-height: 280px;
  }
  &:empty[data-ads-slot="banner"] {
  // Show some backdrops to highlight reserved space instead of looking like a layout error
    background: linear-gradient(45deg, $ads-backdrop-color-1, transparent);
  }
  &[data-ads-slot="banner2"],
  &[data-ads-slot="topmobile"] {
    background: linear-gradient(45deg, $ads-backdrop-color-2, transparent);

    // 'Anzeige' label does reserve space otherwise and causes layout shift
    position: relative;
    & .sdgAnzeigenkennung {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-100%);
    }
  }
}
