.main-container {
  background-color: #fff;
  padding-bottom: 25px;
}

main {
  padding-top: 25px;

  .content {
    padding-bottom: ($grid-gutter-width + $grid-gutter-width);
    flex: 1 0 ($main-content-width + $grid-gutter-width);
    word-wrap: break-word;
  }

  > .row {
    flex-wrap: nowrap;
  }

   h1.page-title {
     margin-bottom: 30px;
   }
}

.layout-fixed main .content {
  max-width: ($main-content-width + $grid-gutter-width);
}
