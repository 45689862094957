$grid-gutter-width: 30px;

$main-content-width: 728px;
$main-sidebar-width: 300px;

$container-max-widths: (
  lg: $main-content-width + $main-sidebar-width + (2 * $grid-gutter-width)
);

$card-cap-bg: none;
$card-border-radius: 0;
$card-border-width: 0;
$card-border-color: none;

$alert-border-radius: 0;

$nav-link-padding-x: 1rem;
$nav-link-padding-y: .5rem;

$navbar-nav-link-padding-y: 8px !default;

$navbar-toggler-color: #000;
$navbar-toggler-border-color: none;
$navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");

$navbar-link-font-size: 1.25rem !default;
$navbar-link-bg: none !default;
$navbar-link-hover-bg: none !default;
$navbar-link-disabled-bg: none !default;
$navbar-link-active-bg: none !default;
$navbar-item-separation-border-color: #ddd !default;

$pagination-bg: #E9E9E9 !default;
$pagination-color: #000 !default;

$headings-margin-bottom: 0.625rem;

$story-headings-color: #333 !default;
$story-headings-font: 'Lato', sans-serif !default;
$story-headings-font-weight: bold !default;

$hamburger-color: #ddd !default;

$border: 1px solid #e9e9e9 !default;

$whatsapp-color: #25d366;
$facebook-color: #1877f2;
$twitter-color: #1da1f2;
$pinterest-color: #e60023;
$youtube-color: #ed1d24;

// Backdrop from this color to transparent for the container reserving space for ads to reduce CLS
$ads-backdrop-color-1: #404040 !default;
$ads-backdrop-color-2: #f3f3f3 !default;
