.brand-logo {
  display: block;

  picture {
    display: block;
  }

  img {
    display: inline-block;
    max-width: 100%;
    height: 40px;
  }
}


.navbar-top {
  padding: 8px 0;

  .row {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .col:first-child {
    flex: 1 1 auto;
  }
}